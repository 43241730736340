import React, { useState } from "react"
import Axios from "axios"
import Layout from "../components/layout"
import Footer from "../components/footer"
import Loader from "../components/loader"
import bodyStyles from "../styles/contactez-nous.module.scss"
import telephone from "../Images/SVG_web_illustrations/DTT_Telephone.svg"
import phoneMobil from "../Images/SVG_web_illustrations/DTT_Telephone_mobile.svg"
import { useTranslation } from "../hooks/useTranslation"
import { useIntl } from "react-intl"

const URL = "https://lc3byb763i.execute-api.ca-central-1.amazonaws.com/Prod/send";

const ContactezNousPage = () => {
    const intl = useIntl();
    const t = useTranslation();

    const [formData, setFormData] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [hasError, setHasError] = useState(false);

    // Handlers

    const updateFormData = (key, value) => {
        formData[key] = value;
        setFormData({...formData});
    }

    const extractFromErrors = (submitted = isSubmitted) => {
        if (!submitted) return [];

        const { name, email, phone, message } = formData;

        let errors = [];
        if (!name || name.length === 0) errors.push("name")
        if (!email || email.length === 0) errors.push("email")
        if (!phone || phone.length === 0) errors.push("phone")
        if (!message || message.length === 0) errors.push("message")

        return errors;
    }

    const submitForm = async () => {
        setIsSubmitted(true);
        
        const errors = extractFromErrors(true);
        if (errors.length > 0) return;

        setIsLoading(true);

        const { name, email, phone, message } = formData;
        const body = {
            "toEmails": ["nicstaubin@gmail.com", "info@doctoctoc.ca"],
            "subject": "DocTocToc | New contact form",
            "message": `name: ${name} \n\nemail: ${email} \n\nphone: ${phone} \n\nmessage: \n${message}`
        };

        try {
            await Axios.post(URL, body);
            setIsSent(true);
        } catch(e) {
            console.log(e);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
        
    }

    // Rendering

    const errors = extractFromErrors();

    return (
        <div>
            <Layout>
                <div className={bodyStyles.body}>
                    <h1>
                        {t("contact.contactez")}
                    </h1>
                    <h1>
                        {t("contact.nous")}
                    </h1>

                    <img className={bodyStyles.mobile_background} src={phoneMobil} alt="background"/>

                    <div className={bodyStyles.form}>
                        <input 
                            onChange={e => updateFormData('name', e.target.value)}
                            className={errors.includes("name") ? bodyStyles.error : null} 
                            placeholder={t("contact.nom")}/>
                        {errors.includes("name") && <label>{t("contact.required")}</label>}

                        <input 
                            onChange={e => updateFormData('email', e.target.value)}
                            className={errors.includes("email") ? bodyStyles.error : null} 
                            placeholder={t("contact.courriel")}/>
                        {errors.includes("email")  && <label>{t("contact.required")}</label>}

                        <input 
                            onChange={e => updateFormData('phone', e.target.value)}
                            className={errors.includes("phone") ? bodyStyles.error : null} 
                            placeholder={t("contact.telephone")}/>
                        {errors.includes("phone")  && <label>{t("contact.required")}</label>}

                        <textarea 
                            onChange={e => updateFormData('message', e.target.value)}
                            className={errors.includes("message") ? bodyStyles.error : null} 
                            placeholder={t("contact.message")}/>
                        {errors.includes("message")  && <label>{t("contact.required")}</label>}

                        { !isSent &&
                            <button onClick={submitForm} disabled={isLoading}>
                                {isLoading ?  <Loader/> : t("contact.envoyer")}
                            </button>
                        }
                        {isSent && <p className={bodyStyles.thankYou}>{t("contact.thankYou")}</p>}
                        {hasError && <p className={bodyStyles.hasError}>{t("contact.error")}</p>}
                    </div>

                    <img className={bodyStyles.desktop_background} src={telephone} alt="background" />
                </div>
            </Layout>
            <Footer color="#53c596" />
        </div>

    )

}

export default ContactezNousPage